import { Box, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import MyHelmet from "../common/MyHelmet";
import SearchBar from "./components/SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSearchData, getAutoSearchData, handleFilters, getPlatformListV2 } from "../../slices/frenziPartner";
import AdvancedSearch from "./components/AdvancedSearch";

import "./_partner-search.scss";
import helpers from "../../services/helpers";
import { getGenreList, getLanguageList, getPlatformList, getPlatformListPartner } from "../../slices/auth";

const STATIC_PLATFORMS = [
  {
    "type": "ott_platform",
    "uuid": "8a75057e-639f-44e4-852f-41a7087873ad",
    "created_at": "2022-04-15T16:56:26.826571",
    "updated_at": "2023-09-27T15:40:25.108836",
    "platform_name": "Netflix",
    "platform_logo": "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ott_v4/netflix.png",
    "platform_country": "IN",
    "tmdb_provider_id": "8",
    "platform_link": "www.netflix.com",
    "android_package": "com.netflix.mediaclient",
    "android_tv_package": "com.netflix.ninja",
    "ios_package": null,
    "component_name": "com.netflix.ninja.MainActivity",
    "watch_on_tv": true,
    "is_active": true,
    "order": 1,
    "jio_stb_component_name": null,
    "jio_stb_package": null
  },
  {
    "type": "ott_platform",
    "uuid": "0dc26c37-bea3-42a6-a10d-296406338439",
    "created_at": "2022-04-15T16:56:26.672120",
    "updated_at": "2023-09-27T15:37:02.762981",
    "platform_name": "Hotstar",
    "platform_logo": "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ott_v4/disney_plus_hotstar.png",
    "platform_country": "IN",
    "tmdb_provider_id": "122",
    "platform_link": "www.hotstar.com",
    "android_package": "in.startv.hotstar",
    "android_tv_package": "in.startv.hotstar",
    "ios_package": null,
    "component_name": "com.hotstar.MainActivity",
    "watch_on_tv": true,
    "is_active": true,
    "order": 1,
    "jio_stb_component_name": null,
    "jio_stb_package": null
  },
  {
    "type": "ott_platform",
    "uuid": "86d5a72d-0468-494b-bc77-2c11b668e9c9",
    "created_at": "2022-04-15T16:56:26.253012",
    "updated_at": "2023-09-27T15:08:23.022381",
    "platform_name": "Amazon Prime Video",
    "platform_logo": "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ott_v4/primevideo.png",
    "platform_country": "IN",
    "tmdb_provider_id": "119",
    "platform_link": "www.primevideo.com",
    "android_package": "com.amazon.avod.thirdpartyclient",
    "android_tv_package": "com.amazon.amazonvideo.livingroom",
    "ios_package": null,
    "component_name": "com.amazon.ignition.IgnitionActivity",
    "watch_on_tv": true,
    "is_active": true,
    "order": 1,
    "jio_stb_component_name": null,
    "jio_stb_package": null
  },
  {
    "type": "ott_platform",
    "uuid": "fe87c089-5a59-4d4e-905c-85e7b003953e",
    "created_at": "2022-04-15T16:56:26.906681",
    "updated_at": "2023-09-27T15:42:08.229348",
    "platform_name": "Sony Liv",
    "platform_logo": "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ott_v4/sonyliv.png",
    "platform_country": "IN",
    "tmdb_provider_id": "237",
    "platform_link": "www.sonyliv.com",
    "android_package": "com.sonyliv",
    "android_tv_package": "com.sonyliv",
    "ios_package": null,
    "component_name": "com.sonyliv.ui.splash.SplashActivity",
    "watch_on_tv": true,
    "is_active": true,
    "order": 1,
    "jio_stb_component_name": null,
    "jio_stb_package": null
  },
  {
    "type": "ott_platform",
    "uuid": "1a6b4a99-2b2e-48e4-85b2-cc96580bf683",
    "created_at": "2022-04-15T16:56:27.376622",
    "updated_at": "2023-09-27T15:44:41.229293",
    "platform_name": "Zee5",
    "platform_logo": "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ott_v4/zee.png",
    "platform_country": "IN",
    "tmdb_provider_id": "232",
    "platform_link": "www.zee5.com",
    "android_package": "com.graymatrix.did",
    "android_tv_package": "com.graymatrix.did",
    "ios_package": null,
    "component_name": "com.zee5.android.launch.presentation.AppStartActivity",
    "watch_on_tv": true,
    "is_active": true,
    "order": 1,
    "jio_stb_component_name": null,
    "jio_stb_package": null
  },
  {
    "type": "ott_platform",
    "uuid": "cfacc070-d67e-4665-8761-fbb4c578de96",
    "created_at": "2022-04-15T16:56:26.724158",
    "updated_at": "2023-09-27T15:37:52.102255",
    "platform_name": "Jio Cinema",
    "platform_logo": "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ott_v4/jio_cinema.png",
    "platform_country": "IN",
    "tmdb_provider_id": "220",
    "platform_link": "www.jiocinema.com",
    "android_package": "com.jio.media.ondemand",
    "android_tv_package": "com.jio.media.stb.ondemand",
    "ios_package": null,
    "component_name": "com.v18.voot.ui.JVHomeActivity",
    "watch_on_tv": true,
    "is_active": true,
    "order": 1,
    "jio_stb_component_name": null,
    "jio_stb_package": null
  }
]

function PartnerSearch(props) {
  const { isNative = false } = props
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [autoSearchData, setAutoSearchData] = useState([]);
  // const [trendingPlatform, setTrendingPlatform] = useState([]);
  const [input, setInput] = useState("");
  const { isLoading, searchData, advFilters } = useSelector((state) => state.frenziPartner);
  const _global = useSelector((state) => state.global);

  useEffect(() => {
    dispatch(getLanguageList());
    dispatch(getPlatformList({ page: 1, pagelimit: 100, is_public: _global?.is_public }));
    dispatch(getPlatformListPartner({ page: 1, pagelimit: 100, is_public: _global?.is_public }));

    // dispatch(getPlatformListV2({ page: 1, pagelimit: 100, is_public: _global?.is_public })).then((resp) => {
    //   if (resp.payload && resp.payload?.data && resp?.payload?.data?.results) {
    //     setTrendingPlatform(resp?.payload?.data?.results)
    //   }
    // }).catch((err) => {
    //   console.log(err)
    // });
    dispatch(getGenreList());
  }, [])

  // let platforms = trendingPlatform && trendingPlatform.length ? trendingPlatform :
  //   auth.allPreferences &&
  //     auth.allPreferences.platforms &&
  //     auth.allPreferences.platforms.length > 5
  //     ? auth.allPreferences.platforms
  //     : auth.platformList?.results
  //       ? auth.platformList?.results
  //       : [];

  let platforms = auth.platformListPartner?.results
    ? auth.platformListPartner?.results
    : [];
  //     let platforms =
  // auth.allPreferences ?
  //   auth.allPreferences?.platforms : []
  // let platforms = auth.platformList?.results
  //   ? auth.platformList?.results
  //   : [];

  const handleAdvancedSearch = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleRecommendedSearch = () => {
    navigate("/");
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      if (isNative) {
        navigate(`/advanced-search/results?q=${input}`)
      } else {
        helpers.setSearchHistoryData(input)
        navigate(`/partner/results?q=${input}`)
      }
    }
  }


  const handleSeacrh = (val) => {
    setIsSearchLoading(true);
    if (isNative) {
      const body = {
        query: val
      }
      dispatch(getAutoSearchData(body)).then((resp) => {
        setIsSearchLoading(false);
        if (resp?.payload?.data && resp?.payload?.data?.results) {
          setAutoSearchData(resp?.payload?.data?.results)
        }
      }).catch((err) => {
        setIsSearchLoading(false);
        console.log(err);
      });
    }
  }

  // const handleSeacrh = useCallback((val) => {
  //   const allFilters = []
  //   for (const [key, value] of Object.entries(advFilters)) {
  //     if ((Array.isArray(value) && value.length > 0) || (value && value != '')) {
  //       allFilters.push({ [key]: value })
  //     }
  //   }

  //   var body = {
  //     query: val,
  //     // custom_filters_es: {
  //     //   any: [
  //     //     {
  //     //       all: allFilters
  //     //     }
  //     //   ]
  //     // },
  //   }

  //   dispatch(getSearchData(body));
  // }, []);

  const handleApply = (e) => {
    handleClose();
    if (isNative) {
      let url = `/advanced-search/results`
      if (input) {
        url += `?q=${input}`
      }
      navigate(url)
    } else {
      let url = `/advanced-search/results?isPartner=true`
      if (input) {
        url += `&q=${input}`
      }
      navigate(url)
      // navigate(`/partner/results?q=${input}`)
    }
  };

  const handlePlatformClick = (item) => {
    dispatch(handleFilters({ ...advFilters, ott_platforms: [{ ...item, ott_name: item?.ott_name || item?.platform_name }] }));
    handleApply()
  }

  return (
    <>
      <MyHelmet
        title={"Partner search page"}
      // description={
      //   "Unveil the Hottest OTT Trends, Stay Updated on the Latest Streaming Buzz and Discover What's Trending in the OTT Worlds"
      // }
      />
      <Grid style={{ minHeight: 'calc(100vh - 100px)' }} container alignItems={'center'} className="new-container h-100 pb-10">
        <div className="partner-search-box-container">
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Grid container>
                <Typography className="fw-700 fs-90 text-selected">
                  Frenzi
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <div className="search-container">
            <Grid
              container
              justifyContent={"center"}
              sx={{
                marginTop: { xs: "10px" },
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <SearchBar
                suggestions={autoSearchData}
                isLoading={isSearchLoading}
                onChange={(val) => handleSeacrh(val)}
                onKeyUp={(e) => handleKeyUp(e)}
                setInput={setInput}
                input={input}
                isNative={isNative}
              />
            </Grid>
            <Grid
              justifyContent={"center"}
              container
              style={{ alignItems: "center" }}
            >
              <div>
                <p
                  onClick={() => handleAdvancedSearch()}
                  className="hover-link fs-24"
                >
                  advanced search
                </p>
              </div>
              <span className="text-selected ml-5 mr-5">|</span>
              <div>
                <p
                  onClick={() => handleRecommendedSearch()}
                  className="hover-link fs-24"
                >
                  recommendations
                </p>
              </div>
            </Grid>
            {STATIC_PLATFORMS && STATIC_PLATFORMS.length > 0 ?
              <Grid
                container
                style={{ flexWrap: "wrap" }}
                justifyContent={"center"}
              >
                <div className="trending-list">
                  <Grid container
                    // sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}
                    justifyContent={"center"}
                    alignContent={'center'}
                    alignItems={'center'}
                  >
                    <span className="fs-20 text-center fw-200 mb-5 mr-5">
                      Trending on :
                    </span>
                    <Link
                      to={`/advanced-search/results`}
                    >
                      {/* <span className="text-selected">
                          {" "}Frenzi
                        </span>, */}
                      <Box className="scale-1 pointer ml-10"
                        component={'img'}
                        sx={{ maxWidth: '45px', height: '45px' }}
                        src={process.env.REACT_APP_CDN_ASSETS_ENDPOINT + "assets/icons/frenziLogoNew.svg"}
                        title="Frenzi"
                      />

                    </Link>
                    {STATIC_PLATFORMS.map((x, i) => {
                      // if (i <= 4) {
                      return (
                        // <Link
                        //   target="_blank"
                        //   to={`/platforms/${x?.ott_name || x?.platform_name}`}
                        // >
                        <span onClick={() => handlePlatformClick(x)}>
                          {" "}
                          {/* <span className="text-selected pointer">
                                {x?.ott_name || x?.platform_name}
                              </span> */}
                          <Box className="scale-1 pointer ml-10"
                            component={'img'}
                            sx={{ maxWidth: '50px', height: '50px' }}
                            src={x?.platform_logo}
                            title={x?.ott_name || x?.platform_name}
                          />
                          {/* {i < (STATIC_PLATFORMS.length - 1) ? "," : ""} */}
                        </span>
                        // </Link>
                      );
                      // }
                    })}
                  </Grid>
                </div>
              </Grid>
              : ''}
            {/* <Grid container justifyContent={"center"}>
              <div className="horizontal-line" />
            </Grid> */}
          </div>

          {/* Advanced search modal */}

          <AdvancedSearch
            open={open}
            // searchData={searchData}
            // isLoading={isLoading}
            searchData={autoSearchData}
            isLoading={isSearchLoading}
            input={input}
            handleClose={handleClose}
            handleSeacrh={handleSeacrh}
            handleKeyUp={handleKeyUp}
            setInput={setInput}
            isNative={isNative}
            handleApply={handleApply}
          />

          {/* <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h5"
                component="h2"
                className="mb-20 text-selected"
              >
                advanced search
              </Typography>
              <div>
                <Grid
                  container
                  justifyContent={"center"}
                  sx={{
                    marginTop: { xs: "10px" },
                    marginBottom: "20px",
                    width: "100%",
                  }}
                >
                  <SearchBar
                    suggestions={searchData}
                    isLoading={isLoading}
                    onChange={(val) => handleSeacrh(val)}
                    onKeyUp={(e) => handleKeyUp(e)}
                    setInput={setInput}
                    input={input}
                  />
                </Grid>
                <Grid container gap={2} justifyContent={"space-between"}>
                  <Grid flex={1} item>
                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        genre :
                      </Grid>
                      <Grid item>
                        <div>
                          {genreList && genreList.length ? (
                            <FormControl size="small" sx={{ m: 1, width: 200 }}>
                              <InputLabel id="demo-multiple-checkbox-label">
                                genre
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={searchMediaFilter?.genre}
                                onChange={handleChange}
                                input={<OutlinedInput label="genres" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {genreList.map((x, i) => (
                                  <MenuItem
                                    key={i}
                                    value={x.genre.toLowerCase()}
                                  >
                                    <Checkbox
                                      checked={searchMediaFilter.genre.includes(
                                        x.genre.toLowerCase()
                                      )}
                                    />
                                    <ListItemText primary={x.genre} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        platforms :
                      </Grid>
                      <Grid item>
                        <div>
                          {platforms && platforms.length ? (
                            <FormControl size="small" sx={{ m: 1, width: 200 }}>
                              <InputLabel id="demo-multiple-checkbox-label">
                                platforms
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={searchMediaFilter?.service}
                                onChange={handleChangePlatform}
                                input={<OutlinedInput label="platforms" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {platforms.map((x, i) => (
                                  <MenuItem key={i} value={x?.code}>
                                    <Checkbox
                                      checked={searchMediaFilter.service.includes(
                                        x?.code
                                      )}
                                    />
                                    <ListItemText
                                      primary={x?.ott_name || "N/A"}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        language :
                      </Grid>
                      <Grid item>
                        <div>
                          {languageList && languageList.length ? (
                            <FormControl size="small" sx={{ m: 1, width: 200 }}>
                              <InputLabel id="demo-multiple-checkbox-label">
                                language
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={searchMediaFilter?.language}
                                onChange={handleChangeLanguage}
                                input={<OutlinedInput label="language" />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                              >
                                {languageList.map((x, i) => (
                                  <MenuItem key={i} value={x?.code}>
                                    <Checkbox
                                      checked={searchMediaFilter.language.includes(
                                        x?.code
                                      )}
                                    />
                                    <ListItemText
                                      primary={x?.language || "N/A"}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        content type :
                      </Grid>
                      <Grid item>
                        <Grid container justifyContent={"space-between"}>
                          <Grid item>
                            <Grid container justifyContent={"space-between"}>
                              <Grid item>
                                <Typography className="fs-15 fw-500">
                                  movies
                                </Typography>
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  className="m-0"
                                  control={
                                    <Checkbox
                                      className="pt-0 text-selected"
                                      checked={searchMediaFilter.content_type.includes(
                                        "movie"
                                      )}
                                      onChange={(e) =>
                                        handleSelectBoxChange(
                                          "content_type",
                                          "movie",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container justifyContent={"space-between"}>
                              <Grid item>
                                <Typography className="fs-15 fw-500">
                                  webseries
                                </Typography>
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  className="m-0 text-selected"
                                  control={
                                    <Checkbox
                                      className="pt-0"
                                      checked={searchMediaFilter.content_type.includes(
                                        "tv"
                                      )}
                                      onChange={(e) =>
                                        handleSelectBoxChange(
                                          "content_type",
                                          "tv",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item flex={1}>
                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        release year :
                      </Grid>
                      <Grid item>
                        <Grid container justifyContent={"space-between"}>
                          <Grid item>
                            <FormControl size="small" sx={{ m: 1, width: 100 }}>
                              <InputLabel id="demo-select-small-label">
                                from
                              </InputLabel>
                              <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                label="from"
                                value={searchMediaFilter?.from_year}
                                onChange={handleChangeFromYear}
                              >
                                {YEARS.map((x, i) => (
                                  <MenuItem key={i} value={x}>
                                    <ListItemText primary={x} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <FormControl size="small" sx={{ m: 1, width: 100 }}>
                              <InputLabel id="demo-select-small-label">
                                to
                              </InputLabel>
                              <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                label="to"
                                value={searchMediaFilter?.to_year}
                                onChange={handleChangeToYear}
                              >
                                {YEARS.map((x, i) => (
                                  <MenuItem key={i} value={x}>
                                    <ListItemText primary={x} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        certificate :
                      </Grid>
                      <Grid item>
                        <div></div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        rating :
                      </Grid>
                      <Grid item>
                        <FormControl size="small" sx={{ m: 1, width: 100 }}>
                          <InputLabel id="demo-select-small-label">
                            rating
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            label="rating"
                            value={searchMediaFilter?.rating}
                            onChange={handleChangeRating}
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x, i) => (
                              <MenuItem key={i} value={x}>
                                <ListItemText primary={x} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        sort by :
                      </Grid>
                      <Grid item>
                        <div>
                          <FormControl
                            sx={{ m: 1, minWidth: 200 }}
                            size="small"
                          >
                            <InputLabel id="demo-select-small-label">
                              sort by
                            </InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={searchMediaFilter.sort}
                              label="sort by"
                              onChange={handleChangeSort}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={"r"}>rating</MenuItem>
                              <MenuItem value={"l"}>popularity</MenuItem>
                              <MenuItem value={"d"}>release date</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid container gap={2} justifyContent={"space-between"}>
                  <Grid item flex={1} >
                    <Grid
                      container
                      className="mb-10"
                      style={{ alignItems: "center" }}
                      justifyContent={"space-between"}
                    >
                      <Grid item className="mr-10">
                        cast :
                      </Grid>
                      <Grid item flex={1}>
                        <Autocomplete
                          disablePortal
                          id="autocomplete"
                          sx={{ width: '100%' }}
                          size="small"
                          renderInput={(params) => (
                            <TextField {...params} label="cast" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item flex={1}>
                  </Grid>
                </Grid>
              </div>
              <Grid container justifyContent={"center"}>
                <Button
                  style={{ marginBottom: "10px" }}
                  onClick={handleApply}
                  className="text-light bg-selected rounded-btn mt-10 mw-100"
                >
                  apply
                </Button>
              </Grid>
            </Box>
          </Modal> */}
        </div>
      </Grid>
    </>
  );
}

export default PartnerSearch;
