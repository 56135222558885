import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthDataService from "../services/auth.services";
import helpers, { MOENGAGE_ATTRIBUTE } from "../services/helpers";
import { KMSClient, EncryptCommand, DecryptCommand } from "@aws-sdk/client-kms";
import { decryptData } from "../services/Utils";


const clientParams = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}

const config = {
    credentials: clientParams,
    endPoint: "",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    signatureVersion: 2,
    region: process.env.REACT_APP_AWS_REGION
}

const client = new KMSClient(config);

const getToken = () => {
    var data = localStorage.getItem("frenzi_user_access");
    var token = data && JSON.parse(data).access;
    return token;
};

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_user_access") ? true : false,
    token: getToken(),
    user: {},
    errMesg: "",
    status: "",
    successMesg: "",
    userData: {},
    otpData: {},
    verifiedData: {},
    languageList: {},
    genreList: {},
    platformList: {},
    platformListPartner: {},
    onboardingTitles: {},
    allPreferences: {},
    profileData: {},
    onboardingMediaCountData: 0
};

export const createUser = createAsyncThunk(
    "native_main/createaccount",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.createAccount(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const userLogin = createAsyncThunk(
    "native_main/loginotp",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.login(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const loginWithOtp = createAsyncThunk(
    "native_main/otplogin",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.loginWithOtp(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const socialLogIn = createAsyncThunk(
    "native_main/socialloginencrypt",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.socialLogIn(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

// onbaording

export const getLanguageList = createAsyncThunk(
    "native_main/languagelist",
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.getLanguageList()
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const updateLangPreference = createAsyncThunk(
    "native_main/updatelanguagelist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.updateLangPreference(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPlatformList = createAsyncThunk(
    "native_main/subscriptionlistv2",
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.getPlatformList(data?.is_free, data?.is_public, data.page, data.pagelimit)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getPlatformListPartner = createAsyncThunk(
    "native_main/subscriptionlistv2part",
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.getPlatformListPartner(data?.is_free, data?.is_public, data.page, data.pagelimit)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? decryptedData : { data: null }
            } else {
                return response.data;
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const updatePlatformPreference = createAsyncThunk(
    "native_main/updatesubscriptionlist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.updatePlatformPreference(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getGenreList = createAsyncThunk(
    "native_main/genrelist",
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.getGenreList()
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const updateGenrePreference = createAsyncThunk(
    "native_main/updategenrelist",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.updateGenrePreference(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getOnboardingTitles = createAsyncThunk(
    "native_main/onboardingtitles",
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.getOnboardingTitles()
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const updateOnboardingTitles = createAsyncThunk(
    "native_main/userbulklike",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.updateOnboardingTitles(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getExistingWatchlist = createAsyncThunk(
    "native_main/onboardingtitles",
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.getExistingWatchlist()
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getAllPreferences = createAsyncThunk(
    "native_main/allpreferences",
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.getAllPreferences(data?.is_public, data?.is_free)
            if (data?.is_public) {
                let decryptedData = await decryptData(response?.data?.data);
                return decryptedData ? { ...decryptedData, ['is_public']: data?.is_public } : { data: null }
            } else {
                return { ...response.data, ['is_public']: data?.is_public };
            }
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getProfileData = createAsyncThunk(
    "native_main/profiledashboard",
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.getProfileData()
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const resendOtp = createAsyncThunk(
    "native_main/resendotp",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.resendOtp(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const getOnboardingMediaCount = createAsyncThunk(
    "native_main/onboardingmediacount",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.getOnboardingMediaCount(data)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const collectLoginInfo = createAsyncThunk(
    "tracking/logininfo",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthDataService.collectLoginInfo(data?.body)
            return response.data;
        } catch (err) {
            return rejectWithValue(err.data ? err.data : {})
        }
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.errMesg = "";
            state.successMesg = "";
            state.isLoading = false;
        },
        setAuthenticatedData: (state, action) => {
            state.token = action.payload && action.payload.access ? action.payload.access : '';
            state.isAuthenticated = true;
        },
        logOut: (state) => {
            state.token = '';
            state.isAuthenticated = false;
            helpers.trackMoengageEvent('FR3_Logout', {
                continue_click: MOENGAGE_ATTRIBUTE.YES
            });
            localStorage.removeItem('frenzi_user_access');
            window.Moengage.destroy_session();
            window.location.href = "/";
        }
    },
    extraReducers: {
        [userLogin.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [userLogin.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
            state.otpData = getPayloadData(state, action);
            // convertAgain(getPayloadData(state, action).data, state);
        },
        [userLogin.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // socialLogIn
        [socialLogIn.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [socialLogIn.fulfilled]: (state, action) => {
            if (action.payload && action.payload.data) {
                state.successMesg = action.payload.message
                state.verifiedData = getPayloadData(state, action);
            }
            // state.otpData = getPayloadData(state, action);
        },
        [socialLogIn.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // createUser
        [createUser.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [createUser.fulfilled]: (state, action) => {
            state.otpData = getPayloadData(state, action);
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [createUser.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // loginWithOtp
        [loginWithOtp.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [loginWithOtp.fulfilled]: (state, action) => {
            if (action.payload && action.payload.data) {
                state.verifiedData = getPayloadData(state, action);
            }
        },
        [loginWithOtp.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // onbaording
        [getLanguageList.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getLanguageList.fulfilled]: (state, action) => {
            state.languageList = getPayloadData(state, action);
        },
        [getLanguageList.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [updateLangPreference.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [updateLangPreference.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [updateLangPreference.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [getPlatformList.pending]: (state, action) => {
            state.platformList = {};
            handlePendingRequest(state, action);
        },
        [getPlatformList.fulfilled]: (state, action) => {
            state.platformList = getPayloadData(state, action);
        },
        [getPlatformList.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [getPlatformListPartner.pending]: (state, action) => {
            state.platformListPartner = {};
            handlePendingRequest(state, action);
        },
        [getPlatformListPartner.fulfilled]: (state, action) => {
            state.platformListPartner = getPayloadData(state, action);
        },
        [getPlatformListPartner.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [updatePlatformPreference.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [updatePlatformPreference.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [updatePlatformPreference.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [getGenreList.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getGenreList.fulfilled]: (state, action) => {
            state.genreList.genres = getPayloadData(state, action);
        },
        [getGenreList.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [updateGenrePreference.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [updateGenrePreference.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [updateGenrePreference.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [getOnboardingTitles.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getOnboardingTitles.fulfilled]: (state, action) => {
            state.onboardingTitles = getPayloadData(state, action);
        },
        [getOnboardingTitles.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [updateOnboardingTitles.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [updateOnboardingTitles.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
        },
        [updateOnboardingTitles.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [getAllPreferences.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getAllPreferences.fulfilled]: (state, action) => {
            if (action.payload && action.payload.is_public) {
                state.genreList = action.payload.data.genres ? { genres: action.payload.data.genres } : {};
                state.languageList = action.payload.data.languages ? { languages: action.payload.data.languages } : {};
                state.platformList = action.payload.data.platforms ? { results: action.payload.data.platforms } : {};
            }
            state.allPreferences = getPayloadData(state, action);
        },
        [getAllPreferences.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        [getProfileData.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getProfileData.fulfilled]: (state, action) => {
            state.profileData = getPayloadData(state, action);
        },
        [getProfileData.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        //resendOtp
        [resendOtp.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [resendOtp.fulfilled]: (state, action) => {
            if (action.payload && action.payload.message) {
                state.successMesg = action.payload.message
                state.isLoading = false
            }
            state.otpData = getPayloadData(state, action);
            // convertAgain(getPayloadData(state, action).data, state);
        },
        [resendOtp.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
        // getOnboardingMediaCount
        [getOnboardingMediaCount.pending]: (state, action) => {
            handlePendingRequest(state, action);
        },
        [getOnboardingMediaCount.fulfilled]: (state, action) => {
            state.onboardingMediaCountData = getPayloadData(state, action);
        },
        [getOnboardingMediaCount.rejected]: (state, action) => {
            handleRejectedAction(state, action)
        },
    },
});
export const { clearMessages, setAuthenticatedData, logOut } = authSlice.actions;

const handlePendingRequest = (state, action) => {
    state.isLoading = true;
    state.successMesg = '';
    state.errMesg = '';
    state.status = '';
}

const getPayloadData = (state, action) => {
    state.isLoading = false;
    return action.payload && action.payload.data ? action.payload.data : {};
}

const handleRejectedAction = (state, action) => {
    state.isLoading = false;
    state.errMesg = action.payload && action.payload.message ? action.payload.message : 'Server error !!!'
}


function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    // return bytes.buffer;
    return bytes;
}


const convertAgain = async (responseText, state) => {
    var cipherText = _base64ToArrayBuffer(responseText);
    const input = {
        KeyId: process.env.REACT_APP_AWS_ARN_KEY_ID,
        CiphertextBlob: cipherText
    }
    try {
        const command = new DecryptCommand(input);
        const response = await client.send(command);
        // process data.
        if (response && response.Plaintext) {
            let win1251decoder = new TextDecoder('windows-1251');
            let bytes = new Uint8Array(response.Plaintext);
            var decryptedText = win1251decoder.decode(bytes);
            var jsonData = JSON.parse(decryptedText);
            if (jsonData && !helpers.isEmptyObject(jsonData)) {
                // state.token = jsonData.access ? jsonData.access : '';
                // state.isAuthenticated = true;
                // localStorage.setItem('frenzi_user_access', JSON.stringify(jsonData));
                // let userData = jsonData;
                // if (userData.langs_pref_set && userData.platforms_pref_set) {
                //     window.location.href = "/";
                // } else {
                //     window.location.href = "/onboarding";
                // }
            }
        } else {
            helpers.toastError('Data decryption failed !!!');
        }
    } catch (error) {
        // error handling.\
        helpers.toastError(error);
    }
}

export default authSlice.reducer;