import React from 'react'
import { Grid, Typography, Stack, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import SectionHeading from '../component/headings/SectionHeading';
import MediumTile from './MediumTile';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation, Pagination } from "swiper";
import NextButtonTile from './NextButtonTile';

const list = [1, 2, 3,];

export default function CommonMediumTiles(props) {
    const { data, heading, isNextBtn, subheading, hideWithoutPoster, allowAll, isNextLink, nextLink, isOtherWatched, avtarIcon, otherCount } = props;

    const newData = hideWithoutPoster ? data.filter((x) => x.poster) : data

    return (
        <>
            <Grid container justifyContent={'space-between'} alignItems={''}>
                <Grid item>
                    <SectionHeading title={heading} subtitle={subheading} />
                </Grid>
                {isNextLink &&
                    <Link to={nextLink}>
                        <ArrowForwardIcon className='pointer' />
                    </Link>
                }
                <Grid item>
                    {isNextBtn &&
                        <ArrowForwardIcon className='pointer' onClick={props.handleNextBtn} />
                    }
                </Grid>
            </Grid>
            {
                isOtherWatched &&
                <Grid item className=''>
                    <Stack direction="row" className='ai-center'>
                        {
                            list.map((x, i) => {
                                return <Avatar alt="Remy Sharp" src={avtarIcon} className='ott-avatar' />
                            })
                        }
                        <Typography className='fs-16 fw-500 lh-23 ml-8'> {otherCount}</Typography>
                    </Stack>
                </Grid>
            }
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                slidesPerView={"auto"}
                spaceBetween={10}
                speed={1000}
                navigation={true}
                modules={[Pagination, FreeMode, Navigation]}
                className="mySwiper"
                freeMode={true}
            >
                {newData && newData.length > 0 && newData.map((x, i) => {
                    if (allowAll || (!allowAll && (x.media_type === 'movie' || x.media_type === 'tv'))) {
                        return <SwiperSlide key={i} className="pr-10" style={{ alignSelf: 'center', width: 'auto' }}>
                            <Grid sx={{
                                maxWidth: { xs: 100, sm: 140 },
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                display: 'inline-block'
                            }}
                            >
                                <MediumTile x={x} refPage={props?.refPage} />
                            </Grid>
                        </SwiperSlide>
                    }
                })}
                {isNextBtn ?
                    <SwiperSlide className="pr-10" style={{ width: 'auto', height: "auto" }} key={"next-btn"}>
                        <Grid className='h-100' sx={{
                            maxWidth: { xs: 100, sm: 140 },
                            display: 'inline-block'
                        }}
                        >
                            <NextButtonTile data={data} isBtn={true} onClick={props?.handleNextBtn} />
                        </Grid>
                    </SwiperSlide>
                    : ''
                }
                {isNextLink ?
                    <SwiperSlide className="pr-10" style={{ width: 'auto', height: "auto" }} key={"next-btn"}>
                        <Grid className='h-100' sx={{
                            maxWidth: { xs: 100, sm: 140 },
                            display: 'inline-block'
                        }}
                        >
                            <NextButtonTile data={data} link={nextLink} />
                        </Grid>
                    </SwiperSlide>
                    : ''
                }
            </Swiper>
        </>
    )
}

