import axios from "axios";
import axiosInstance from "../http-common";
import { encryptData } from "./Utils";
import helpers from "./helpers";

const getSearchData = (body = {}) => {
    // let url = `native_main/aisearch?page=${page}&pagelimit=${pagelimit}&query=${query}`;
    let url = `native_main/aisearch`;
    // let url = `https://tidy-dassie-adapting.ngrok-free.app/ai_search`;
    if(body?.page > 1){
        url += `?page=${body?.page}`
    }
    return axiosInstance.post(url, body);
    // return axios.post(url, body);
};

const getCastData = (q, limit = 40, page = 1) => {
    let url = `native_main/personsearch?query=${q}&pagelimit=${limit}&page=${page}`;
    return axiosInstance.get(url);
};

const getAutoSearchData = (q, limit = 40, page = 1) => {
    let url = `native_main/autocomplete?query=${q}&pagelimit=${limit}&page=${page}&client_type=jio`;
    return axiosInstance.get(url);
};

const getAdvancedSearchData = (body, limit = 40, page = 1) => {
    let url = `native_main/advancedsearch?pagelimit=${limit}&page=${page}`;
    if(body.sort && body.sort != 'l'){
        url += `&sort=${body.sort}`
    }
    const newBody = {
        ...body,
        show_only_ott: true
    }
    return axiosInstance.post(url, newBody);
};

const getPlatformListV2 = async (is_free = false, is_public = false, page, pagelimit) => {
    let endPoint = 'subscriptionlistv2'
    var url = `native_main/${is_public ? 'public_' : ''}${endPoint}?page=${page}&pagelimit=${pagelimit}`;
    if (is_free) {
        url += `&is_free=${is_free}`
    }
    let captcha_token = '';
    // let captcha_token = helpers.getCaptchaToken();

    if (is_public) {
        let newBody = helpers.getQueryParams(url.split('?')[1]);
        url = url.split('?')[0];
        newBody = { ...newBody, captcha_token: captcha_token }
        let encryptedData = await encryptData(newBody);
        return axiosInstance.post(url, { data: encryptedData });
    } else {
        return axiosInstance.get(url);
    }
};

const FrenziPartnerDataService = {
    getSearchData,
    getCastData,
    getAutoSearchData,
    getAdvancedSearchData,
    getPlatformListV2
};

export default FrenziPartnerDataService;