import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import helpers from "../../services/helpers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FreeMode, Navigation } from "swiper";
import { useDispatch, useSelector } from "react-redux";

const breakpoints = {
  319: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  425: {
    slidesPerView: 3.5,
    spaceBetween: 15,
  },
  640: {
    slidesPerView: 5,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 5.5,
    spaceBetween: 20,
    slidesPerGroup: 4.5,
  },
  1024: {
    slidesPerView: 7,
    spaceBetween: 20,
    slidesPerGroup: 6,
  },
  1440: {
    slidesPerView: 9,
    spaceBetween: 20,
    slidesPerGroup: 8,
  },
  1880: {
    slidesPerView: 11,
    spaceBetween: 30,
    slidesPerGroup: 10,
  },
  2560: {
    slidesPerView: 14,
    spaceBetween: 30,
    slidesPerGroup: 13,
  },
};

const breakpoints2 = {
  319: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
  425: {
    slidesPerView: 3.5,
    spaceBetween: 15,
  },
  640: {
    slidesPerView: 5,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 5.5,
    spaceBetween: 20,
    slidesPerGroup: 4.5,
  },
  1024: {
    slidesPerView: 7,
    spaceBetween: 20,
    slidesPerGroup: 6,
  },
  1440: {
    slidesPerView: 7,
    spaceBetween: 20,
    slidesPerGroup: 8,
  },
  1880: {
    slidesPerView: 9,
    spaceBetween: 30,
    slidesPerGroup: 10,
  },
  2560: {
    slidesPerView: 12,
    spaceBetween: 30,
    slidesPerGroup: 13,
  },
};

export default function CastCrew(props) {
  const { data, nextLink, isNextBtn, isProfileClick = false, withAd, hideWithoutPoster, onSlideChange } = props;

  const media = useSelector((state) => state.media);
  const _global = useSelector((state) => state.global);

  const newData = hideWithoutPoster ? data.filter((x) => x.poster) : data

  return (
    <Grid
      container
      className="app-section"
      sx={{ marginTop: { xs: "20px", sm: "30px" } }}
      justifyContent={"center"}
    >
      <Grid item xs={12}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <h2
              className={`fs-28 lh-28 ${props.subheading ? "pb-10" : "pb-10"
                } m-0 fw-700`}
            >
              {props.heading}
            </h2>
            {/* <h2 className="fs-24 fw-500 lh-14 mt-0 mb-10  text-dark-grey">
              {props.subheading ? props.subheading : ""}
            </h2> */}
          </Grid>
          <Grid item>
            {nextLink && nextLink !== "" && (
              <Link to={nextLink}>
                <ArrowForwardIcon className="pointer" />
              </Link>
            )}
            {isNextBtn &&
              <ArrowForwardIcon className='pointer' onClick={props.handleNextBtn} />
            }
          </Grid>
        </Grid>
        <Swiper
          slidesPerView={2.2}
          spaceBetween={20}
          // slidesPerGroup={5}
          freeMode={true}
          navigation={true}
          speed={1000}
          breakpoints={withAd ? breakpoints2 : breakpoints}
          modules={[FreeMode, Navigation]}
          className="mySwiper"
          onSlideChange={onSlideChange}
        >
          {newData &&
            newData.length > 0 &&
            newData.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  {isProfileClick ?
                    <Grid item onClick={() => props?.onProfileClick(item)} className="mw-100 scale-1 pointer" sx={{ padding: "20px 0px" }}>
                      <Box
                        alt="cast"
                        component="img"
                        sx={{
                          height: 150,
                          width: "100%",
                          maxHeight: { xs: 150, sm: 150 },
                          maxWidth: { xs: 100, sm: 100 },
                          borderRadius: "9px",
                        }}
                        src={
                          item.profile_path
                            ? helpers.getFullUrl(item.profile_path, 185)
                            : item.poster
                              ? helpers.getFullUrl(item.poster, 185)
                              : helpers.getDefaultImage("cinescoregenres")
                        }
                      />
                      <p className="m-0 fs-14 fw-500">{item.name}</p>
                      <p
                        style={{ color: "#979797", width: "100%" }}
                        className="m-0 fs-14 fw-500 ellipsis"
                      >
                        {item.role
                          ? item.role
                          : item.character
                            ? item.character
                            : ""}
                      </p>
                    </Grid>
                    :
                    <Link
                      to={`/person/${item?.unique_title || item?.name.replace(/ /g, "-")
                        }${item?.unique_title
                          ? ""
                          : "/" + (item?.uuid || item?.person)
                        }`}
                      key={i}
                    >
                      <Grid item className="mw-100" sx={{ padding: "20px 0px" }}>
                        <Box
                          alt="cast"
                          component="img"
                          sx={{
                            height: 150,
                            width: "100%",
                            maxHeight: { xs: 150, sm: 150 },
                            maxWidth: { xs: 100, sm: 100 },
                            borderRadius: "9px",
                          }}
                          src={
                            item.profile_path
                              ? helpers.getFullUrl(item.profile_path, 185)
                              : item.poster
                                ? helpers.getFullUrl(item.poster, 185)
                                : helpers.getDefaultImage("cinescoregenres")
                          }
                        />
                        <p className="m-0 fs-14 fw-500">{item.name}</p>
                        <p
                          style={{ color: "#979797", width: "100%" }}
                          className="m-0 fs-14 fw-500 ellipsis"
                        >
                          {item.role
                            ? item.role
                            : item.character
                              ? item.character
                              : ""}
                        </p>
                      </Grid>
                    </Link>
                  }
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Grid>
    </Grid>
  );
}
